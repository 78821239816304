import React from 'react'
import styled from 'styled-components'

const Heading = styled.h2`
  font-weight: normal;
  margin-bottom: 5px;
  color: #333;
`

const PastConcerts = ({ year, concerts }) => {
  const reduced = concerts.reduce((acc, curr) => {
    const idx = acc.findIndex(
      ({ club, city }) => club + city === curr.club + curr.city
    )
    return idx === -1 ? [...acc, curr] : acc
  }, [])

  return (
    <>
      <Heading>{year.toUpperCase()}</Heading>
      <div>
        {reduced.map((concert, idx) => (
          <React.Fragment key={`${concert.club}/${concert.city}`}>
            <span>{concert.club}</span>, <span>{concert.city}</span>
            {idx !== reduced.length - 1 && <span> - </span>}
          </React.Fragment>
        ))}
      </div>
    </>
  )
}

export default PastConcerts
