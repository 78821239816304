import React from 'react'
import PropTypes from 'prop-types'
import { useStaticQuery, graphql } from 'gatsby'
import moment from 'moment'
import styled from 'styled-components'
import { Grid, Item, Row } from '../components/flexGrid'
import PastConcerts from '../components/pastConcerts'

const Table = styled.table`
  border-spacing: 0px;
  width: 100%;
  th {
    font-size: 28px;
    color: #333;
    padding: 8px;
    text-transform: uppercase;
    font-weight: normal;
    text-align: left;
  }
  td,
  th {
    padding: 8px;
    border-bottom: solid 2px #1e1e1e;
  }
  tbody tr:last-child td {
    border-bottom: none;
  }
`

const Concerts = ({ pageContext }) => {
  const { thisLabel, thereLabel, thenLabel } = pageContext
  const data = useStaticQuery(graphql`
    query Concerts {
      contentJson {
        items {
          date
          city
          desc
          time
          club
        }
      }
    }
  `)

  const [upcoming, past] = data.contentJson.items.reduce(
    (acc, curr) => {
      const date = new Date(moment(curr.date, 'DD.MM.YY', true).format())
      const now = new Date()
      now.setHours(0, 0, 0, 0)
      if (date < now) {
        // concert is in past
        const year = date.getFullYear()
        const item = { ...acc[1] }
        if (item[year]) {
          item[year].push(curr)
        } else {
          item[year] = [curr]
        }

        return [acc[0], item]
      }

      return [[...acc[0], curr], acc[1]]
    },
    [[], {}]
  )

  return (
    <Grid>
      <Row>
        <Item w={6}>
          <Table>
            <thead>
              <tr>
                <th>{thisLabel}</th>
                <th>{thereLabel}</th>
                <th>{thenLabel}</th>
              </tr>
            </thead>
            <tbody>
              {!upcoming.length && (
                <tr>
                  <td colSpan="3" style={{ textAlign: 'center' }}>
                    No upcoming Concerts fixed so far.
                  </td>
                </tr>
              )}
              {upcoming.map((concert, idx) => (
                <tr key={idx}>
                  <td>{concert.desc}</td>
                  <td>
                    {concert.club}
                    <br />
                    {concert.city}
                  </td>
                  <td>
                    {concert.date}
                    <br />
                    {concert.time}
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>
        </Item>
        <Item w={6}></Item>
      </Row>
      {Object.keys(past)
        .sort((a, b) => (a > b ? -1 : 1))
        .map((year) => (
          <Row key={year}>
            <Item w={6}>
              <PastConcerts year={year} concerts={past[year]} />
            </Item>
            <Item w={6} />
          </Row>
        ))}

      <Row></Row>
    </Grid>
  )
}

Concerts.propTypes = {
  pageContext: PropTypes.shape({
    uri: PropTypes.string.isRequired,
  }).isRequired,
}

export default Concerts
